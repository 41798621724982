.p-galleria {
  & .p-galleria-indicators {
    & .p-galleria-indicator {
      &.p-highlight {
        & button {
          background-color: #fff !important;
        }
      }
    }
  }
}
